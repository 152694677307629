@import '../../../variables.css';

* {
    box-sizing: border-box;
}

.main {
    height: auto;
    display: flex;
    padding-left: 56px;
}

.promoCardContainer {
    width: 100%;
}

.promoHeading {
    font-size: var(--ark-32-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-40-line-height);
    color: var(--ark-gray-medium-color);
    margin: 0;
    margin-bottom: 1rem;
}

.content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sideCenter {
    padding-left: 3rem;
    justify-content: center;
}

.adFree .sideCenter {
    padding-right: 3rem;
}

.seo {
    height: auto;
    margin-top: 0; /* 6.25rem */
    margin-bottom: 5.5rem;
}

.seogrid {
    margin: 0 56px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas: 'seocard1 seocard2 seocard3';
    grid-gap: 1em;
}

.seogrid .seoItem:nth-child(1) {
    grid-row-start: 1;
    grid-row-end: 2;

    grid-column-start: 1;
    grid-column-end: 2;
}

.seogrid .seoItem:nth-child(2) {
    grid-row-start: 1;
    grid-row-end: 2;

    grid-column-start: 2;
    grid-column-end: 3;

    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
}

.seogrid .seoItem:nth-child(3) {
    grid-row-start: 1;
    grid-row-end: 2;

    grid-column-start: 3;
    grid-column-end: 4;

    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
}

.row {
    display: grid;
    margin-bottom: 3.1875rem;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: 'content-col';
    grid-gap: 1rem;
    padding-bottom: 1.5rem;
    margin-top: -1.5rem;
}

.row.mobileRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileAdDivider {
    margin-left: 1rem;
}

.rowContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    grid-area: content-col;
}

.fourTilesBlock {
}

.homeSeoTitle,
.fourTilesBlock h2 {
    font-size: var(--ark-32-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-40-line-height);
    color: var(--ark-gray-medium-color);
    margin: 0;
    margin-bottom: 0.5rem;
}

.homeSeoTitle,
.homeSeoSubTitle {
    margin: 0 3.5rem;
    margin-bottom: 1rem;
}

.homeSeoSubTitle {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-gray-medium-color);
    font-weight: var(--ark-font-weight-regular);
    margin-bottom: 1.3rem;
}

.fourTilesBlock_games {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 0.75rem;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'fourTilesBlock_game1 fourTilesBlock_game2'
        'fourTilesBlock_game3 fourTilesBlock_game4';
}

.fourTilesBlock_game {
}

.fourTilesBlock_game:nth-child(1) {
    grid-area: fourTilesBlock_game1;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.fourTilesBlock_game:nth-child(2) {
    grid-area: fourTilesBlock_game2;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.fourTilesBlock_game:nth-child(3) {
    grid-area: fourTilesBlock_game3;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.fourTilesBlock_game:nth-child(4) {
    grid-area: fourTilesBlock_game4;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.row:nth-child(1) {
    margin-top: 3rem;
}

.visibleSmallDevices {
    display: none;
}

.visibleLargeDevices {
    display: grid;
}

.visibleGrid {
    display: grid;
}

footer {
    height: auto;
    background: var(--ark-gray-dark-color);
}

.adDividerClassNameLicensing button,
.adDividerClassNameSharp button {
    width: 118%;
    margin-left: -9%;
}

.promoDividerClassName button {
    width: 100%;
}

.adDividerClassNameLicensing button,
.adDividerClassNameSharp button,
.promoDividerClassName button {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

.adDividerClassNameSharp button > span,
.promoDividerClassName button > span {
    font-size: var(--ark-18-font-size) !important;
}

.adDividerClassNameLicensing svg,
.adDividerClassNameSharp svg,
.promoDividerClassName svg {
    margin-left: 0.3rem;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .promoHeading {
        padding-left: 1.25rem;
    }

    .promoHeading,
    .homeSeoTitle {
        font-size: var(--ark-22-font-size) !important;
        font-weight: var(--ark-font-weight-bold) !important;
        line-height: var(--ark-32-line-height) !important;
    }

    .homeSeoSubTitle {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
    }

    .row:nth-child(1) {
        margin-top: 1rem;
    }

    .row.fullscreen {
        margin-left: -1.25rem;
    }

    .content {
        width: 100%;
        overflow: visible;
    }

    .main {
        padding-left: 0;
    }

    .seo {
        margin-top: 0;
    }

    .seogrid {
        margin: 0;
        padding-left: 1.25rem;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
    }

    .row {
        margin-bottom: 2.5rem !important;
    }

    .adFree .row {
        margin-bottom: 1.5rem !important;
    }

    .adDividerClassNameLicensing button,
    .adDividerClassNameSharp button,
    .promoDividerClassName button {
        width: 100%;
        margin-left: 0;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .seo {
        margin-left: 1.25rem;
    }

    .homeSeoTitle,
    .homeSeoSubTitle {
        margin-left: 0;
    }
}

@media (min-width: var(--ark-large-mobile-768)) and (max-width: var(--ark-medium-desktop-below-1199)) {
    .seo {
        margin-top: 0;
    }

    .adBeforeSeo {
        padding-bottom: 0;
        margin-bottom: 3.875rem !important;
    }
}

@media (min-width: var(--ark-small-desktop-1025)) and (max-width: var(--ark-medium-desktop-below-1199)) {
    .promoFullscreen {
        margin-right: 3.5rem;
    }
}

@media (min-width: var(--ark-small-desktop-1025)) {
    .row {
        grid-template-areas: 'content-col sidee';
        grid-gap: 1rem;
    }
}

.side,
.sideCenter {
    flex-shrink: 0;
    display: none;
    align-items: center;
    justify-content: flex-end;
    grid-area: sidee;
}

@media (min-width: var(--ark-medium-desktop-1200)) {
    .row {
        grid-template-columns: minmax(0, 1fr) 390px;
    }

    .adFree .row {
        grid-template-columns: minmax(0, 1fr);
    }

    .side,
    .sideCenter {
        display: flex;
    }

    .row.mobileRow {
        display: none;
    }
}

@media (max-width: var(--ark-small-mobile-below-575)) {
    .visibleSmallDevices {
        display: grid;
    }

    .visibleLargeDevices {
    }
}
