@import '../../../../variables.css';

.seoCardContainer {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.seoCard {
    box-shadow: 0 10px 20px var(--ark-black-15-color);
    border-radius: 1.25rem;
    background-color: var(--ark-neutral-white);
    margin-bottom: 1rem;
    flex-grow: 1;
    cursor: pointer;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.button {
    max-width: 22.5rem;
}

.header {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    height: 5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.header.FIRST {
    background-image: linear-gradient(to right, #094555, #2b8072);
}

.header.SECOND {
    background-image: linear-gradient(to right, #51309a, #7442c3);
}

.header.THIRD {
    background-image: linear-gradient(to right, #1862ba, #1695f4);
}

.headerImg {
    min-width: 35.25rem;
}

.content {
    padding: 2rem;
    padding-top: 1rem;
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
    color: var(--ark-gray-medium-color);
    font-weight: var(--ark-font-weight-regular);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .seoCardContainer {
        width: 16.375rem;
    }

    .content {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
        padding-bottom: 3rem;
    }

    .seoCard {
        /* height: 18.625rem; */
    }
}

@media (min-width: var(--ark-small-desktop-figma-1024)) and (max-width: var(--ark-medium-desktop-below-1199)) {
    .buttonCaption {
        font-size: 0.9375rem !important;
    }
    .button {
        min-height: 4rem;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .buttonContainer .button .buttonCaption {
        font-size: var(--ark-18-font-size);
    }
}
