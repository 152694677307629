@import "../../../variables.css";

.buttonWrapper {
    background: none;
    box-shadow: none;
    border: 0;
    width: 100%;
    height: auto;
    cursor: pointer;
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.container {
    display: flex;
    box-shadow: 0 40px 40px -30px var(--ark-tertiary-light-color);
    border-radius: 1.25rem;
    width: 100%;
    height: 18.75rem;
    border: 0;
    background-color: var(--ark-tertiary-light-color);
}

.info {
    width: 22.5rem;
    flex-shrink: 0;
    padding: 2rem;
    padding-bottom: 1.75rem;
    background-color: var(--ark-tertiary-light-color);
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.caption {
    font-size: var(--ark-26-font-size);
    line-height: var(--ark-32-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-neutral-white);
    margin: 0;
}

.description {
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-neutral-white);
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
}

.description.withoutCaption {
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-32-line-height);
}

.buttonContainer {
    width: 16.5rem;
}
.imageWrapper{
    flex-grow: 1;
    overflow: hidden;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}
.image {
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--ark-gray-extra-light-color);
    transition: all 0.64s var(--ark-ease-in-out-sine);
    background-size: cover;
    width: 100%;
    height: 100%;
}

.container:hover .image {
    transform: scale(1.1);
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .buttonWrapper {
        height: auto;
    }

    .container {
        flex-direction: column-reverse;
        height: auto;
        border-radius: 0;
    }

    .info {
        padding-top: 0.5rem;
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 1.25rem;
        border-bottom-right-radius: 1.25rem;
    }

    .caption {
        font-size: var(--ark-22-font-size) !important;
        line-height: var(--ark-32-line-height) !important;
    }

    .description {
        font-size: var(--ark-14-font-size) !important;
        line-height: var(--ark-label1-line-height) !important;
        margin-bottom: 0;
    }

    .image {
        border-radius: 1.25rem;
        margin: 1.25rem;
        height: 18.75rem;
    }

    .buttonContainer {
        display: none;
    }
}
