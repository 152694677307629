@import "../../../../variables.css";

.container {
    padding-top: 6rem;
    box-sizing: border-box;
    cursor: pointer;
}

.content {
    width: 17.5rem;
    box-shadow: 0 10px 20px var(--ark-black-15-color);
    background-color: var(--ark-neutral-white);
    border-radius: 1.25rem;
    padding: 2rem;
    padding-top: 5rem;
    position: relative;
    box-sizing: border-box;
}

.image {
    width: 14.125rem;
    height: 9.875rem;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, -67%);
    object-fit: contain;
}

.caption {
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-h3-line-height);
    font-weight: var(--ark-font-weight-bold);
    color: var(--ark-gray-dark-color);
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
}

.description {
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    font-weight: var(--ark-font-weight-regular);
    color: var(--ark-gray-medium-color);
    text-align: left;
    margin-top: 0;
    margin-bottom: 1rem;
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    .caption {
        margin-bottom: 0;
    }

    .description {
        margin-bottom: 1rem;
    }

    .content {
        width: 22.25rem;
        padding-left: 1.5rem;
        padding-right: 1.75rem;
        padding-bottom: 1.5rem;
    }
}
